import { Avatar, Box, IconButton, Typography, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { useMemo, useState, useCallback } from 'react';
import { postPrintConversation } from './store/asyncActions';

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  padding: theme.spacing(0.4)
}));

const HeaderUser = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1)
}));

const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  position: 'fixed',
  maxHeight: theme.spacing(8),
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: 1100,
  top: theme.spacing(1),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(theme.direction === 'ltr' && {
    marginLeft: `calc(${theme.spacing(20)})`,
    width: `calc(100% - ${theme.spacing(20)} - ${theme.spacing(5)})`,
    ...(open && { right: 0 }),
  }),
  ...(theme.direction === 'rtl' && {
    width: `calc(100% - ${theme.spacing(24)})`,
    marginLeft: `calc(${theme.spacing(4)})`,
  }),
  ...(open && {
    width: theme.direction === 'ltr' ? `calc(100% - ${width}px - ${theme.spacing(6)})` : `calc(100% - ${width}px - ${theme.spacing(6)})`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(theme.direction === 'ltr' && {
      marginRight: `calc(${theme.spacing(3)})`,
    }),
    ...(theme.direction === 'rtl' && {
      marginLeft: `calc(${theme.spacing(3)})`,
    }),
  }),
}));

export default function AdvisorHeader() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const user = useSelector((state) => state.user);
  const lastConversation = useSelector((state) => state.lastConversation);
  const drawerWidth = useSelector((state) => state.drawerWidth);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const locale = useSelector((state) => state.locale);
  const isPrinting = useSelector((state) => state.loaders.printConversation);

  useMemo(() => {
    const activities = lastConversation?.activities ? lastConversation.activities : [];
    const result = activities
      .filter(({ type }) => type === 'message')
      .filter(({ channelData: { postBack } = {}, from: { role } }) => !(role === 'user' && postBack))
    setMessages(result);
  }, [lastConversation]);

  const handlePrintConversation = useCallback(() => {
    const id = lastConversation?.id;
    if(id){
      dispatch(postPrintConversation(id, locale));
    }
  }, [lastConversation, locale, dispatch]);

  return (
    <HeaderContainer
      open={sidebarOpen}
      width={drawerWidth}
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        bgcolor: 'background.paper',
      }}
    >
      {messages.length > 0 && (
        <HeaderBox sx={{
          alignSelf: 'flex-end'
        }}>
          <IconButton
            className='smallIconButton'
            onClick={handlePrintConversation}
            disabled={isPrinting}
            aria-label="Download conversation"
          >
            {isPrinting ? <CircularProgress size={24} /> : <DownloadIcon />}
          </IconButton>
        </HeaderBox>)
      }
      {user && (
        <HeaderUser>
          <Typography variant="body1" component="p" sx={{
            px: theme.spacing(1)
          }}>{user.name}</Typography>
          <Avatar alt={user.name} src={user.image} />
        </HeaderUser>
      )}
    </HeaderContainer>
  );
}

// File: AttachFileButton.jsx

import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return <PictureAsPdfIcon fontSize="small" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <ImageIcon fontSize="small" />;
    case 'doc':
    case 'docx':
      return <DescriptionIcon fontSize="small" />;
    default:
      return <InsertDriveFileIcon fontSize="small" />;
  }
};

const AttachFileButton = ({ handleAttach }) => {
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const triggerAttach = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const apiUrl = process.env.REACT_APP_API_URL; // e.g., 'http://localhost:7071/api'
    const newUploadedFiles = [];
    const uploadedFilesInfo = []; // Array to collect info about all uploaded files

    for (const file of files) {
      try {
        // Read the file as an ArrayBuffer
        const arrayBuffer = await file.arrayBuffer();

        // Send the binary data to the server
        console.log('filename', file.name);
        const response = await fetch(`${apiUrl}/uploadfile`, {
          method: 'POST',
          headers: {
            'Content-Type': file.type || 'application/octet-stream',
            'x-file-name': file.name, // Include the original file name
          },
          body: arrayBuffer,
        });

        if (response.ok) {
          const result = await response.json();
          console.log('File uploaded successfully:', result);
          // Add the file name to the list of uploaded files
          newUploadedFiles.push(file.name);
          // Collect info about the uploaded file
          uploadedFilesInfo.push({ fileName: file.name, contentUrl: result.blobUrl, contentType: file.type});
        } else {
          const errorText = await response.text();
          console.error('Error uploading file:', response.status, errorText);
          // Optionally, handle errors per file
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // Optionally, handle errors per file
      }
    }

    // Update the state with the new uploaded files
    setUploadedFiles((prevFiles) => [...prevFiles, ...newUploadedFiles]);

    // Notify the parent component after all files are uploaded
    if (handleAttach) {
      handleAttach(uploadedFilesInfo); // Pass array of uploaded files info
    }

    // Reset the file input
    event.target.value = '';
  };

  return (
    <Box display="flex" alignItems="center">
      {/* Attach File Button */}
      <IconButton onClick={triggerAttach} className="smallIconButton">
        <AttachFileIcon />
      </IconButton>

      {/* Display the list of uploaded files */}
      <List
        dense
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: { xs: '150px', sm: '200px', md: '250px' }, // Responsive max width
          overflowX: 'auto', // Allow horizontal scrolling if needed
          padding: 0,
          margin: 0,
        }}
      >
        {uploadedFiles.map((fileName, index) => (
          <ListItem key={index} sx={{ padding: 0, width: 'auto' }}>
            <Tooltip title={fileName}>
              <ListItemIcon
                sx={{
                  minWidth: '30px',
                  cursor: 'pointer', // Add cursor pointer on hover
                }}
              >
                {getFileIcon(fileName)}
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        ))}
      </List>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple // Allows selecting multiple files
      />
    </Box>
  );
};

export default AttachFileButton;
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Import thunk as a named export
import rootReducer from './reducers'; // Import your root reducer

export default createStore(
  rootReducer,
  applyMiddleware(thunk)
);

/*import { createStore } from 'redux';

import reducer from './reducers';

export default createStore(reducer);
*/
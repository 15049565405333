import React, { useState } from 'react';
import { 
    Typography, 
    Box, 
    Card,
    CardContent,
    CardActions,
    Button,
    CardMedia} from '@mui/material';
import { t } from 'i18next';
import ReactMarkdown from 'react-markdown';

const sasToken = process.env.REACT_APP_SASTOKEN; 
const storageAccountName = process.env.REACT_APP_STORAGEACCOUNT;

export default function Sources ({documents, setSelectedSource, setOpen}) {

    const handleOpenSource = (source) => {
        setSelectedSource(source)
        setOpen(true);
    }

    const isJpg = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return extension === 'jpg' || extension === 'jpeg';
    };

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return extension === 'mp4' || extension === 'mov' || extension === 'mov';
    };

    const decodeBase64Title = (encodedTitle) => {
        return decodeURIComponent(encodedTitle);
    }

    return (
    <>
        <Box
            sx={{
                display:'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                p:2
            }}
            >
            {documents.map((document, index) => (
                <Card
                key={index}
                sx={{
                    width: 170
                }}
                >
                <CardContent>   
                    { isJpg(document.title) && 
                        <CardMedia 
                        onClick={()=>handleOpenSource(document)}
                        style={{ height: 140, width: '100%' }}
                        title={decodeBase64Title(document.filepath)}
                        image={`https://${storageAccountName}.blob.core.windows.net/data/docs/${document.title}?${sasToken}`} >        
                        </CardMedia>
                    }
                    { isVideo(document.title) && 
                        <video
                            onClick={() => handleOpenSource(document)}
                            style={{ height: 140, width: '100%' }}
                            controls
                            title={decodeBase64Title(document.filepath)}
                            src={`https://${storageAccountName}.blob.core.windows.net/data/docs/${document.title}?${sasToken}`}
                        >
                            Your browser does not support the video tag.
                        </video>
                    }
                    <Typography variant='h6' component='h6'
                    sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                    }}
                    >{decodeBase64Title(document.title)}</Typography>
                    <Typography
                    variant="body2"
                    component="p"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,
                        textOverflow: 'ellipsis',
                        height: 100
                    }}
                    ><ReactMarkdown>{document.content}</ReactMarkdown>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={()=>handleOpenSource(document)} size="small">{t('sources.view')}</Button>
                </CardActions>
                </Card>
            ))}
        </Box>
    </>
    )
}
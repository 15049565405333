import React, { useState, useRef, useCallback } from 'react';
import { 
    Box, 
    TextField, 
    IconButton, 
    InputAdornment,
    Modal
} from '@mui/material';
import { t } from 'i18next';
import SearchIcon from '@mui/icons-material/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from '@mui/material/styles';
import { hooks } from 'botframework-webchat-component';
import { useDispatch } from 'react-redux';
import { setLastConversation, sendActionMessage } from './store/actions'

const { useSendMessage } = hooks;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewConversationModl({open, handleClose}){
    const dispatch = useDispatch();
    const theme = useTheme();
    const sendMessage = useSendMessage();
    const [boxValue, setBoxValue] = useState('');
    const promptRef = useRef(null);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleSubmit();
        }
      };
  
      const handleValueChange = (event) => {
        setBoxValue(event.target.value);
      };
  
      const handleSubmit = useCallback(() => {
        const message = promptRef.current.value;
        dispatch(setLastConversation({
          activities: [],
          message
        }));
        setBoxValue('');
        handleClose();
      }, [sendMessage]);
  
      const handleAttach = () => {
  
      }

    return <Modal
        open={open}
        onClose={handleClose}
        sx={{
            direction: theme.direction
        }}
        >
          <Box sx={style}>
            <Box  sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'top', mb: 2 }}>
            <TextField
              fullWidth
              inputRef={promptRef}
              onKeyDown={handleKeyPress}
              autoFocus
              onChange={handleValueChange}
              value={boxValue}
              variant="outlined"
              placeholder={t('sendBox.placeholder')}
              multiline
              rows={4}
              InputProps={{
                style: { fontSize: '1.5rem' }, // Adjust the font size as needed
                startAdornment: (
                  <InputAdornment position="start" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                    <IconButton onClick={handleAttach} className="smallIconButton">
                      <AttachFileIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                    <IconButton onClick={handleSubmit} className="smallIconButton">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          </Box>
      </Modal>
}
import React from 'react';
import { 
    Typography, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { BlobServiceClient } from '@azure/storage-blob';

const sasToken = process.env.REACT_APP_SASTOKEN; //"sv=2022-11-02&ss=bf&srt=o&sp=r&se=2025-07-19T00:49:45Z&st=2024-07-18T16:49:45Z&spr=https,http&sig=3c47COSJ9%2BYN8sIym20Cyj3jEO4u3KVAHE%2FXbGXS5OY%3D";
const containerName = "data";
const storageAccountName = process.env.REACT_APP_STORAGEACCOUNT;

export default function SourceModal({source, open, handleClose}){
    const theme = useTheme();
    
    const downloadFile = async () => {
        try {
            const blobName = 'docs/' + source.filepath;
            const blobService = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
            );

            const containerClient = blobService.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobName);
            const downloadBlockBlobResponse = await blobClient.download();
            const blob = await downloadBlockBlobResponse.blobBody;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = source.filepath;
            link.click();

            // Clean up
            window.URL.revokeObjectURL(link.href);
            return blobName;
        } catch (error) {
            console.error('Error uploading file:', error.message);
            return null;
        }
    };

    const isJpg = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return extension === 'jpg' || extension === 'jpeg';
    };

    const isVideo = (filename) => {
      const extension = filename.split('.').pop().toLowerCase();
      return extension === 'mp4' || extension === 'mov' || extension === 'mov';
    };

    const decodeBase64Title = (encodedTitle) => {
        return decodeURIComponent(encodedTitle);
    }

    const getFilenameWithoutExtension = (filepath) => {
      // Split the filepath by '/' and get the last segment (the filename)
      const filenameWithExtension = filepath.split('/').pop();
    
      // Remove the extension by splitting at the last '.' and taking the first part
      const filenameWithoutExtension = filenameWithExtension.split('.').slice(0, -1).join('.');
      console.log(filenameWithoutExtension);
      return filenameWithoutExtension;
    };

    return (
        source && 
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        sx={{
            direction: theme.direction
        }}
      >
        <DialogTitle id="dialog-title">
            <Typography id="modal-title" variant="h6" component="h2">
                {decodeURIComponent(source.title)}
            </Typography>
        </DialogTitle>
        <DialogContent>
          { isJpg(source.title) && 
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 250,
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <img
              src={`https://${storageAccountName}.blob.core.windows.net/data/docs/${source.filepath}?${sasToken}`}
              alt={decodeBase64Title(source.title)}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </Box>
          }
          { isVideo(source.title) && 
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 250,
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <video
              src={`https://${storageAccountName}.blob.core.windows.net/data/video/${source.title}?${sasToken}`}
              alt={decodeBase64Title(source.title)}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              controls
              title={decodeBase64Title(document.title)}
            >
              <track
                kind="subtitles"
                src={`https://${storageAccountName}.blob.core.windows.net/data/transcriptions/${getFilenameWithoutExtension(source.title)}.srt?${sasToken}`}
                srcLang="he"
                label="Hebrew"
                default
              />
            </video>
          </Box>
          }
          <DialogContentText>
            <Typography variant='body' component='p' id="modal-description" sx={{ mt: 2, whiteSpace: 'pre-line' }}>
                {source.content}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('sources.close')}
          </Button>
          <Button onClick={downloadFile} autoFocus>
            {t('sources.download')}
          </Button>
        </DialogActions>
      </Dialog>
    )
}
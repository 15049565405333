import {
  SET_USER,
  LOAD_USER,
  SET_LASTCONVERSATION,
  LOAD_LASTCONVERSATION,
  ADD_LASTCONVERSATION_ACTIVITY,
  SET_LOCALE,
  SET_THEME_MODE,
  SET_THEME_DIRECTION,
  SET_SIDEBAR_OPEN,
  SET_HISTORY_OPEN,
  SET_AVATAR_OPEN,
  SET_INCONVERSATION,
  SET_ADVISOR_LOADING,
  SET_DISPLAYED_BOT_MESSAGE,
  SET_BOT_TYPING,
  SET_BOT_UPLOADING,
  SEND_ACTION_MESSAGE,
  setThemeDirection,
  SET_INSETTINGS
} from './actions';

import {
  SET_USER_REQUEST,
  SET_USER_SUCCESS,
  SET_USER_FAILURE,
  SET_CONVERSATION_REQUEST,
  SET_CONVERSATION_SUCCESS,
  SET_CONVERSATION_FAILURE,
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_FAILURE,
  ADD_CONVERSATION_ACTIVITY_FAILURE,
  ADD_CONVERSATION_ACTIVITY_REQUEST,
  ADD_CONVERSATION_ACTIVITY_SUCCESS,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  POST_PRINT_CONVERSATION_REQUEST,
  POST_PRINT_CONVERSATION_SUCCESS,
  POST_PRINT_CONVERSATION_FAILURE,
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE
} from './asyncActions'

// Make a more compelling demo than just changing background color

const DEFAULT_STATE = {
  user: null,
  message: null,
  conversations: [],
  questions: [],
  inconversation: false,
  insettings: false,
  lastConversation: null,
  locale: 'he',
  themeMode: 'light',
  themeDirection: 'rtl',
  drawerWidth: 320,
  sidebarOpen: true,
  historyOpen: false,
  avatarOpen: true,
  lastBotMessage: {},
  loaders: {
    printConversation: false,
    saveConversation: false,
    advisorLoading: false
  },
  instagram: {
    access_token: null,
    expire_in: null
  }
};

const reducers = (state = DEFAULT_STATE, { payload, type }) => {
  console.log(type, payload);
  switch (type) {
    case SET_USER:
      state = { ...state, user: payload.user };
      break;
    case LOAD_USER:
      state = {
          ...state,
          user: payload,
      };
      break;
    case SET_LASTCONVERSATION:
      state = { 
        ...state,
        inconversation: payload.lastConversation.activities.length > 0,
        lastConversation: payload.lastConversation 
      };
      break;
    case LOAD_LASTCONVERSATION:
      state = {
          ...state,
          inconversation: payload?.activities.length > 0,
          lastConversation: payload,
      };
      break;
    case ADD_LASTCONVERSATION_ACTIVITY:
      const { activity } = payload;
      state.lastConversation.activities.push(activity);
      const modified = {
        ...state.lastConversation
      }
      state = {
        ...state,
        lastConversation: modified
      }
      break;
    case SET_LOCALE:
      state = { ...state, locale: payload.locale};
      reducers(state, {
        payload: setThemeDirection(payload.locale === 'en' ? 'ltr' : 'rtl'),
        type: SET_THEME_DIRECTION
      });
      break;
    case SET_THEME_MODE:
      state = { ...state, themeMode: payload.themeMode };
      break;
    case SET_THEME_DIRECTION:
      state = { ...state, themeDirection: payload.themeDirection };
      break;
    case SET_SIDEBAR_OPEN:
      state = { ...state, sidebarOpen: payload.sidebarOpen };
      break;
    case SET_HISTORY_OPEN:
      state = { ...state, historyOpen: payload.historyOpen };
      break;
    case SET_AVATAR_OPEN:
      state = { ...state, avatarOpen: payload.avatarOpen };
      break;
    case SET_INCONVERSATION:
      state = { ...state, inconversation: payload.inconversation};
      break;
    case SET_INSETTINGS:
      state = { ...state, insettings: payload.insettings };
      break;
    case SET_ADVISOR_LOADING:
      state = { ...state, loaders: {
        ...state.loaders,
        advisorLoading: payload
      } };
      break;
    case SET_DISPLAYED_BOT_MESSAGE:
      state = { ...state, lastBotMessage: payload.message };
      break;
    case SET_BOT_TYPING:
      state = { ...state, isBotTyping: payload.typing };
      break;
    case SET_BOT_UPLOADING:
      state = { ...state, isBotUploading: payload.uploading };
      break;
    case SEND_ACTION_MESSAGE:
      state =  { ...state, message: payload.message };
      break;
    case SET_USER_REQUEST:
      state =  { ...state, loading: true, error: null };
      break;
    case SET_USER_SUCCESS:
      state = { ...state, loading: false };
      break;
    case SET_USER_FAILURE:
      state = { ...state, loading: false, error: payload };
      break;
    case FETCH_CONVERSATIONS_REQUEST:
      state =  { ...state, loading: true, error: null };
      break;
    case FETCH_CONVERSATIONS_SUCCESS:
      state = { ...state, loading: false, conversations: payload ? payload : [] };
      break;
    case FETCH_CONVERSATIONS_FAILURE:
      state = { ...state, loading: false, error: payload };
      break;
    case SET_CONVERSATION_REQUEST:
      state =  { ...state, loaders: {
        ...state.loaders,
        saveConversation: true
      }, error: null };
      break;
    case SET_CONVERSATION_SUCCESS:
      state = { ...state, loaders: {
        ...state.loaders,
        saveConversation: false
      }};
      break;
    case SET_CONVERSATION_FAILURE:
      state = { ...state, loaders: {
        ...state.loaders,
        saveConversation: false
      }, error: payload };
      break;
    case ADD_CONVERSATION_ACTIVITY_REQUEST:
      state =  { ...state, loading: true, error: null };
      break;
    case ADD_CONVERSATION_ACTIVITY_SUCCESS:
      state = { ...state, loading: false };
      break;
    case ADD_CONVERSATION_ACTIVITY_FAILURE:
      state = { ...state, loading: false, error: payload };
      break;
    case FETCH_QUESTIONS_REQUEST:
      state =  { ...state, loading: true, error: null };
      break;
    case FETCH_QUESTIONS_SUCCESS:
      state = { ...state, loading: false, questions: payload ? payload : [] };
      break;
    case FETCH_QUESTIONS_FAILURE:
      state = { ...state, loading: false, error: payload };
      break;
    case POST_PRINT_CONVERSATION_REQUEST:
      state =  { ...state, loaders: {
        ...state.loaders,
        printConversation: true
      }, error: null };
      break;
    case POST_PRINT_CONVERSATION_SUCCESS:
      state = { ...state, loaders: {
        ...state.loaders,
        printConversation: false
      } };
      break;
    case POST_PRINT_CONVERSATION_FAILURE:
      state = { ...state, loaders: {
        ...state.loaders,
        printConversation: false
      }, error: payload };
      break;
    case FETCH_SETTINGS_REQUEST:
      state =  { ...state, loading: true, error: null };
      break;
    case FETCH_SETTINGS_SUCCESS:
      state = { ...state, loading: false, instagram: { 
        ...state.instagram,
        access_token: payload?.instagram_access_token,
        expire_in: payload?.instagram_expires_in
      }};
      break;
    case FETCH_SETTINGS_FAILURE:
      state = { ...state, loading: false, error: payload };
      break;
    default:
      break;
  }

  return state;
}

export default reducers;
import './App.css';
import React from 'react';
import { connect } from 'react-redux';
import { createDirectLine, createStore } from 'botframework-webchat';
import { Components } from 'botframework-webchat-component';
import dispatchIncomingActivityMiddleware from './store/dispatchIncomingActivityMiddleware';
import { Typography, Container, Box, CircularProgress } from '@mui/material';
import '@fontsource/roboto';
import '@fontsource/alef'; // Import Alef font
import AdvisorView from './AdvisorView';
import { setUser, setLastConversation, addLastConversationActivity, setAdvisorLoading } from './store/actions';
import { setConversation, fetchConversations } from './store/asyncActions';

class AdvisorApp extends React.Component {
  constructor(props) {
    super(props);
    const { user, locale, message, lastConversation, incomingActivity, loaders} = props;
    this.store = createStore({}, incomingActivity);

    this.state = {
      user,
      message,
      locale,
      lastConversation,
      watermark: null,
      directLine: null,
      loaders: loaders
    };
  }

  componentDidMount() {
    const { directLine, user } = this.state;
    if(!directLine && user){
      this.fetchToken();
    } 
  }

  componentDidUpdate(prevProps) {
    const { locale, user, message, lastConversation, loaders } = this.props;

    if (prevProps.locale !== locale) {
      this.setState({ locale });
    }

    if (prevProps.user !== user) {
      this.setState({ user });
    }

    if (message && prevProps.message?.timestamp !== message?.timestamp) {
      this.setState({message});
    }

    if (prevProps.lastConversation && prevProps.lastConversation?.id !== lastConversation?.id) {
      this.setState({ lastConversation }, ()=>{
        if (!loaders?.advisorLoading){
          if(lastConversation.message){
            this.setState({ message: lastConversation.message})
          }
          this.reloadDirectLine();
        }
      });
    }
  }

  endDirectLine() {
    const { directLine } = this.state;
    if (directLine) directLine.end();
  }

  reloadDirectLine() {
    this.endDirectLine();
    this.fetchToken();
  }

  async fetchToken() {
    const { user, lastConversation } = this.state;
    const botId = process.env.REACT_APP_BOTID;
    const langUrl = `https://defaultaa2ed11e70e143ce98cc70921f99ec.45.environment.api.powerplatform.com/powervirtualagents/botsbyschema/${botId}/directline/token?api-version=2022-03-01-preview`;

    this.props.setAdvisorLoading(true);

    try {
      let id, token, conversationId, watermark;
      if (lastConversation) {
        ({ id, token, conversationId, watermark } = lastConversation);
      }

      if(!watermark){
        watermark = `act-${Math.random().toString(36).substr(2, 9)}`;
        this.setState({ watermark });
      }
  
      if (!conversationId) {
        ({token} = await this.fetchDirectLineToken(langUrl));
      } else {
        try {
          const reconnectData = await this.fetchReconnectUrl(conversationId, token, watermark);
          console.log('reconnect data', reconnectData);
          if (reconnectData) {
            ({ token, conversationId } = reconnectData);
          } else {
            conversationId = null;
            ({token} = await this.fetchDirectLineToken(langUrl));
          }
        } catch (error) {
          console.error('Failed to reconnect to conversation:', error);
          throw new Error('Failed to reconnect to conversation.');
        }
      }

      const directLineURL = await this.fetchDirectLineUrl(langUrl);
      const conversation = {
        domain: new URL('v3/directline', directLineURL),
        conversationId,
        token
      };
      const directLine = createDirectLine(conversation);
      this.initializeDirectLine(directLine, user, token, id);

  
      // Refresh token every 30 minutes (1800 seconds), subtract 5 minutes (300 seconds) as buffer.
      //setTimeout(() => this.refreshTokenTimer(), (1800 - 300) * 1000);
    } catch (error) {
      this.props.setAdvisorLoading(false);
      console.error('Error fetching Direct Line token or URL:', error);
    }
  }
  
  async refreshTokenTimer() {
    const { user, lastConversation } = this.state;
    const result = await this.refreshToken();
    const { token, expires_in } = result;
    lastConversation.token = token;
    this.props.setConversation(user.id, lastConversation);
    // Set a new timer to refresh the token before it expires
    setTimeout(() => this.refreshTokenTimer(), (expires_in - 300) * 1000); // Refresh 5 minutes before expiration
  }

  async refreshToken() {
    const { directLine } = this.state;
    const oldToken = directLine.token;
    const refreshUrl = `https://europe.directline.botframework.com/v3/directline/tokens/refresh`;

    try {
      const response = await fetch(refreshUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${oldToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      return await response.json();

    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }

  async fetchReconnectUrl(conversationId, token, watermark) {
    try {
      const response = await fetch(`https://europe.directline.botframework.com/v3/directline/conversations/${conversationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 403){
        return null;
      }
      if (!response.ok) {
        throw new Error(`Failed to reconnect to conversation. Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching reconnect URL:', error);
      throw error;
    }
  }

  async fetchDirectLineUrl(langUrl) {
    try {
      const apiVersion = new URL(langUrl).searchParams.get('api-version');
      const response = await fetch(new URL(`/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`, langUrl));
      if (!response.ok) throw new Error(`Failed to retrieve regional channel settings. Status: ${response.status}`);
      const { channelUrlsById: { directline } } = await response.json();
      return directline;
    } catch (error) {
      console.error('Error fetching Direct Line URL:', error);
      throw error;
    }
  }

  async fetchDirectLineToken(langUrl) {
    try {
      const response = await fetch(langUrl);
      if (!response.ok) throw new Error(`Failed to retrieve Direct Line token. Status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching Direct Line token:', error);
      throw error;
    }
  }

  initializeDirectLine(directLine, user, token, id) {
    const CONNECTION_UNINITIALIZED = 0;
    const CONNECTION_ONLINE = 2;
    
    const subscription = directLine.connectionStatus$.subscribe({
      next: async (value) => {
        console.log(value);
        if (value === CONNECTION_UNINITIALIZED){
          console.log(directLine)
          this.setState({directLine});
          this.props.setAdvisorLoading(true);
        }
        if (value === CONNECTION_ONLINE) {
          try {
            const conversation = {
              id,
              conversationId: directLine.conversationId,
              token
            };
            console.log(conversation);
            this.props.setConversation(user.id, conversation);
          } catch (error) {
            console.error('Error sending startConversation event:', error);
          }finally{
            subscription.unsubscribe();
            this.props.setAdvisorLoading(false);
          }
        }
      },
      error: (err) => {
        this.props.setAdvisorLoading(false);
        console.error('Error with connectionStatus$ subscription:', err);
      },
    });
    directLine.postActivity({
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      name: 'startConversation',
      type: 'event',
    }).subscribe(
      id => {
        const { message } = this.state;
        console.log('sending message', message);
        if(message){
          directLine.postActivity({
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: 'message',
            text: message,
          }).subscribe(
            id => {
              this.setState({message: null});
              this.props.fetchConversations(user.id);
              console.log('Posted first message, assigned ID ', id);
            },
            error => {
              console.log('Error posting activity', error);
            }
          );
        }
        console.log('Posted start conversation, assigned ID ', id)
      },
      error => {
        console.log('Error posting activity', error);
      }
    );
  }
  
  async sendMessge(directLine, message) {
    try {
      await directLine.postActivity({
        localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: 'message',
        text: message,
      }).toPromise();
      this.setState({message:null});
      
    } catch (error) {
      console.error('Error sending init message:', error);
    }
  }

  async startConversation() {
    const { locale, directLine, message } = this.state;
    try {
      await directLine.postActivity({
        localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale,
        name: 'setConversationToken',
        type: 'event',
        value: directLine.token,
      }).toPromise();
      if(message){
        await directLine.postActivity({
          localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          locale,
          type: 'message',
          text: message.text,
        }).toPromise();
        this.setState({message:null});
      }
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  }

  async fetchConversationHistory(conversationId, token, watermark) {
    try {
      const response = await fetch(`https://europe.directline.botframework.com/v3/directline/conversations/${conversationId}/activities`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`Failed to retrieve activities. Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.activities;
    } catch (error) {
      console.error('Error fetching conversation history:', error);
      throw error;
    }
  }

  async saveConversation(id, conversationId, token, userId, watermark, activities) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const conversation = {id, conversationId, userId, token, watermark , activities};

      const res = await fetch(`${apiUrl}/conversation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(conversation),
      });
      if (!res.ok) throw new Error(`Failed to save conversation. Status: ${res.status}`);
      const result = await res.json();
      return result.conversation;
    } catch (error) {
      console.error('Error saving conversation:', error);
      throw error;
    }
  }

  async getConversations(userId) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const res = await fetch(`${apiUrl}/conversations?userId=${userId}&limit=10`);
      if (!res.ok) throw new Error(`Failed to fetch conversations. Status: ${res.status}`);
      const result = await res.json();
      return result.conversations;
    } catch (error) {
      console.error('Error fetching conversations:', error);
      throw error;
    }
  }

  connectToWebSocket(streamUrl) {
    if (this.webSocket) {
      this.webSocket.close();
    }
  
    this.webSocket = new WebSocket(streamUrl);
  
    this.webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.activities) {
        this.handleIncomingActivities(message.activities);
      }
    };
  
    this.webSocket.onclose = () => {
      console.warn('WebSocket connection closed.');
    };
  
    this.webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }
  
  async handleIncomingActivities(activities) {
    activities.forEach(activity => {
      // Handle each activity
      console.log('Received activity:', activity);
    });

  }

  render() {
    const { directLine } = this.state;
    return (
      <>
        {directLine ? (
          <Components.Composer store={this.store} directLine={directLine}>
            <AdvisorView />
          </Components.Composer>
        ) : (
          <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
          </Container>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  incomingActivity: dispatchIncomingActivityMiddleware(dispatch),
  addActivity: (conversation, activity) => dispatch(addLastConversationActivity(conversation, activity)),
  setUser: (user) => dispatch(setUser(user)),
  setConversation: (userId, conversation)=>dispatch(setConversation(userId, conversation)),
  setLastConversation: (lastConversation) => dispatch(setLastConversation(lastConversation)),
  setAdvisorLoading: (isLoading) => dispatch(setAdvisorLoading(isLoading)),
  fetchConversations: (userId) => dispatch(fetchConversations(userId))
});

export default connect(
  ({ user, 
    lastConversation, 
    locale, 
    sidebarOpen, 
    drawerWidth, 
    themeMode, 
    themeDirection,
    message,
    loaders }) => ({
    user,
    lastConversation,
    locale,
    sidebarOpen,
    drawerWidth,
    themeMode,
    themeDirection,
    message,
    loaders
  }),
  mapDispatchToProps
)(AdvisorApp);

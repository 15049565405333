import React, { useState, useMemo, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import logo from './assets/logo.png';
import logoDark from './assets/logo-dark.png';
import daizy from './assets/daizy.png';
import daizySecondary from './assets/daizy.png';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarOpen, setLastConversation, setInconversation, setInsettings } from './store/actions';
import { fetchConversations } from './store/asyncActions';
import LocaleSelector from './LocaleSelector';
import ThemeModeSelector from './ThemeModeSelector';
import NewConversationModal from './NewConversationModal';
import { t } from 'i18next';


const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(13)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(14)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerwidth }) => ({
    width: drawerwidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, drawerwidth),
      '& .MuiDrawer-paper': openedMixin(theme, drawerwidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidebar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const drawerWidth = useSelector((state) => state.drawerWidth);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const conversations = useSelector((state) => state.conversations);
  const locale = useSelector((state) => state.locale);
  const [libraryOpen, setLibraryOpen] = useState(true);
  const [sortedConversations, setSortedConversations] = useState([]);
  const [newConversationOpen, setNewConversationOpen] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const lastConversation = useSelector((state) => state.lastConversation);

  useEffect(() => {
    dispatch(fetchConversations(user.id));
  }, [fetchConversations, user]);

  useMemo(()=>{
    const sorted = [...conversations].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setSortedConversations(sorted);
  },[conversations])

  const handleDrawerToggle = () => {
    dispatch(setSidebarOpen(!sidebarOpen));
  };

  const handleLibraryToggle = () => {
    setLibraryOpen(!libraryOpen);
    if (!sidebarOpen) {
      dispatch(setSidebarOpen(true));
    }
  };

  const handleSelectConversation = (conversation) => {
    dispatch(setLastConversation(conversation));
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleDateString(locale === 'en' ? 'en-US' : 'he-IL', options);
  };

  const truncateText = (text, maxLength) => {
    // Check if the text length exceeds the maximum length
    if (text && text.length > maxLength) {
      // Cut the text to the maximum length and add ellipsis
      return text.slice(0, maxLength) + '...';
    }
    // Return the original text if it's within the limit
    return text;
  }

  const handleNewConversationClose = () =>{ 
    setNewConversationOpen(false);
  }

  const handleOpenNewConversation = () => {
    setNewConversationOpen(true);
  }

  const handleHome = () => {
    dispatch(setInconversation(false));
    dispatch(setInsettings(false));
  }

  const handleSettings = () => {
    dispatch(setInsettings(true));
    dispatch(setInconversation(false));
  }
  
  return (
    <Box sx={{ display: 'flex', direction: theme.direction }}>
      <Drawer
        background={theme.palette.background.default}
        variant="permanent"
        open={sidebarOpen}
        drawerwidth={drawerWidth}
        anchor={theme.direction === 'ltr' ? 'left' : 'right'}
      >
        <Box sx={{ minHeight: sidebarOpen ? 110 : 56, p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            {sidebarOpen ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                  <img src={isDarkMode ? logoDark : logo} alt="deebee.ai logo" width={190} />
                </Box>
                <IconButton onClick={handleDrawerToggle} className="largeIconButton">
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </Box>
            ) : (
              <IconButton onClick={handleDrawerToggle} className="largeIconButton">
                <img src={isDarkMode ? daizySecondary : daizy} alt="deebee.ai daizy" width={30} />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <List sx={{ padding: 2, direction: theme.direction === 'rtl' ? 'rtl' : 'ltr' }}>
          <ListItemButton
            onClick={handleOpenNewConversation}
            sx={{
              bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400],
              color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
              justifyContent: sidebarOpen ? 'flex-start' : 'center',
            }}
          >
            <ListItemIcon sx={{ px: 1, minWidth: 0, justifyContent: 'center' }}>
              <AddIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.new')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          <ListItemButton onClick={handleHome} sx={{ justifyContent: sidebarOpen ? 'flex-start' : 'center' }}>
            <ListItemIcon sx={{ px: 1, minWidth: 0, justifyContent: 'center' }}>
              <SearchIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.home')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          <ListItemButton sx={{ justifyContent: sidebarOpen ? 'flex-start' : 'center' }} onClick={handleLibraryToggle}>
            <ListItemIcon sx={{ px: 1,minWidth: 0, justifyContent: 'center' }}>
              <LocalLibraryIcon className="largeIconButton" />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText primary={t('sidebar.library')} sx={{ textAlign: theme.direction === 'rtl' ? 'right' : 'left' }} />
            )}
          </ListItemButton>
          {sidebarOpen && (
            <Collapse 
            in={libraryOpen} 
            timeout="auto" 
            unmountOnExit
            sx={{ mx: theme.spacing(3) }}
          >
            {sortedConversations.map((conversation, index) => (
              <List disablePadding key={index}>
              <ListItemButton
                onClick={() => handleSelectConversation(conversation)}
                selected={conversation.id === lastConversation?.id} // Highlight current conversation
                sx={{
                  backgroundColor: conversation.id === lastConversation?.id ? theme.palette.action.selected : 'inherit',
                  display: 'flex',
                  alignItems: 'center', // Ensure children align properly
                }}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 'auto', px: 2 }}>
                  <QuestionAnswerIcon className="largeIconButton" />
                </ListItemIcon>
                {sidebarOpen && (
                  <ListItemText
                    primary={truncateText(conversation.title, 17) || formatDate(conversation.createdAt)}
                    sx={{
                      textAlign: theme.direction === 'rtl' ? 'right' : 'left',
                      fontSize: '0.875rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: 'calc(100% - 48px)', // Subtract icon space to ensure ellipsis shows up
                    }}
                  />
                )}
              </ListItemButton>
            </List>
            ))}
          </Collapse>
          )}
        </List>
        <Box sx={{ flexGrow: 1 }} /> {/* This will push the next list to the bottom */}
        <Divider />
        <Stack direction={sidebarOpen ? 'row' : 'column'} sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LocaleSelector />
          <ThemeModeSelector />
          <IconButton className="largeIconButton" onClick={handleSettings}>
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Drawer>
      <NewConversationModal open={newConversationOpen} handleClose={handleNewConversationClose} />
    </Box>
  );
}

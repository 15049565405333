import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { styled, Box } from '@mui/material';
import { fetchSettings } from './store/asyncActions';
import { t } from 'i18next';

const SettingsBox = styled('div')(({ theme, width, open }) => ({
  position: 'relative',
  minHeight: '100vh',
  paddingTop: theme.spacing(10),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(14),
  boxShadow: 1,
  borderRadius: 2,
  ...(theme.direction === 'ltr' && {
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(7),
  }),
  ...(theme.direction === 'rtl' && {
    marginRight: theme.spacing(3),
  }),
  ...(open && {
    ...(theme.direction === 'ltr' && {
      marginRight: `${width}px`,
      marginLeft: theme.spacing(4),
    }),
    ...(theme.direction === 'rtl' && {
      marginRight: `${width}px`,
      marginLeft: theme.spacing(4),
    }),
  }),
}));

const Settings = () => {
  const dispatch = useDispatch();
  const { user, sidebarOpen, drawerWidth, locale } = useSelector((state) => ({
    user: state.user,
    sidebarOpen: state.sidebarOpen,
    drawerWidth: state.drawerWidth,
    locale: state.locale
  }));
  const instagram = {
        access_token: useSelector((state) => state.instagram.access_token),
        expire_in: useSelector((state) => state.instagram.expire_in)
    }

  const [tokenExpiration, setTokenExpiration] = useState(null);

  useEffect(() => {
    dispatch(fetchSettings(user.id));
     // Calculate the expiration date
  }, []);

  useMemo(()=>{
    console.log(instagram.expire_in);
    if(instagram.expire_in){
        const expirationDate = new Date(instagram.expire_in);
        setTokenExpiration(expirationDate);
    }
  },[instagram.expire_in])


  const handleInstagramAuth = () => {
    const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_INSTAGRAM_REDIRECT_URI;
    const state = JSON.stringify({ user_id: user.id, lang: locale });

    const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=user_profile,user_media&response_type=code&state=${encodeURIComponent(state)}`;

    const width = 500;
    const height = 600;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const authWindow = window.open(
      instagramAuthUrl,
      'InstagramAuth',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );

    const timer = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(timer);
        dispatch(fetchSettings(user.id));
      }
    }, 500);
  };

  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString(locale === 'en' ? 'en-US' : 'he-IL', options);
  };

  const isTokenValid = tokenExpiration && tokenExpiration > new Date();
  console.log(isTokenValid);

  return (
    <Box>
      <SettingsBox open={sidebarOpen} width={drawerWidth}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('settings.title')}
        </Typography>
        
        <Button
          variant="contained"
          color="primary"
          onClick={handleInstagramAuth}
          size="large"
        >
          {isTokenValid ? t('settings.instagram.reconnect') : t('settings.instagram.connect')}
        </Button>
        {isTokenValid && (
          <Typography variant="body1" color="textSecondary">
             {`${t('settings.instagram.expiration')}: ${formatDate(tokenExpiration)}`}
          </Typography>
        )}
      </SettingsBox>
    </Box>
  );
};

export default Settings;

import { Box, TextField, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { hooks } from 'botframework-webchat-component';
import { styled } from '@mui/material/styles';
import React, { useCallback, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { sendActionMessage } from './store/actions';

const { useSendMessage } = hooks;

const SendBoxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(theme.direction === 'ltr' && {
    marginLeft: theme.spacing(9),
    width: `calc(100% - ${width}px - ${theme.spacing(24)})`,
    transform: 'translateX(-50%)',
    left: `calc(50% - ${width/2}px + ${theme.spacing(11)})`
  }),
  ...(theme.direction === 'rtl' && {
    marginRight: theme.spacing(8),
    width: `calc(100% - ${width}px - ${theme.spacing(24)})`,
    transform: 'translateX(-50%)',
    left: `calc(50% - ${width/2}px + ${theme.spacing(11)})`
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(theme.direction === 'ltr' && {
      left: `calc(50% + ${theme.spacing(10)})`
    }),
    ...(theme.direction === 'rtl' && {
      transform: 'translateX(-50%)',
      left: `calc(50% - ${width/2}px)`
    }),
  }),
}));

export default function AdvisorSendbox({ onSubmit }) {
  const dispatch = useDispatch();
  const promptRef = useRef(null);
  const drawerWidth = useSelector((state) => state.drawerWidth);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const sendMessage = useSendMessage();
  const [boxValue, setBoxValue] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = useCallback(() => {
    const message = promptRef.current.value;
    sendMessage(message);
    /*dispatch(sendActionMessage({
      text: message,
      timestamp: new Date().toISOString()
    }));*/
    setBoxValue('');
    if (onSubmit) {
      onSubmit(message);
    }
  }, [sendMessage, onSubmit]);

  const handleValueChange = (event) => {
    setBoxValue(event.target.value);
  };

  return (
    <SendBoxContainer
      open={sidebarOpen}
      width={drawerWidth}
      sx={{
        position: 'fixed',
        height: '50px',
        bottom: 40,
        mb: 5,
      }}
    >
      <Box
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 3,
          display: 'flex',
          flexDirection: 'column',

          boxShadow: 1,
          width: '75%',
          bgcolor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <TextField
          fullWidth
          label={t('sendBox.followup')}
          variant="outlined"
          inputRef={promptRef}
          onKeyDown={handleKeyPress}
          autoFocus
          onChange={handleValueChange}
          value={boxValue}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
            ),
          }}

        />
      </Box>
    </SendBoxContainer>
  );
}

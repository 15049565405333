import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'he',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                language: {
                    english: 'English',
                    hebrew: 'Hebrew'
                },
                sendBox: {
                    placeholder: 'Ask here',
                    followup: 'Ask follow-up'
                },
                header: {
                    slogan: 'REIMAGINE YOUR DATA',
                    glogin: 'Login with google'
                },
                sidebar: {
                    new: 'New conversation',
                    home: 'Home',
                    library: 'Library'
                },
                history:{
                    title: 'History'
                },
                sources: {
                    view: 'view',
                    download: 'download',
                    close: 'close'
                },
                avatar:{
                    title: 'Avatar'
                },
                conversation: {
                    expand: 'Expand for details',
                    answer: 'Answer',
                    sources: 'Sources',
                },
                login:{
                    title: 'Login'
                },
                stepper: {
                    next: 'Next',
                    back: 'Back'
                },
                footer: {
                    blog: 'blog',
                    linkedin: 'linkedIn',
                    phone: '+972-50-4949469',
                    email: 'email'
                },
                settings: {
                    title: 'System settings',
                    instagram: {
                        connect: 'Connect to instagram',
                        reconnect: 'Reconnect to instagram',
                        expiration: 'Instagram token expires on'
                    }
                }
            },
        },
        he: {
            translation: {
                language: {
                    english: 'אנגלית',
                    hebrew: 'עברית'
                },
                sendBox: {
                    placeholder: 'אני כאן בכדי לעזור לך',
                    followup: 'שאלת המשך'
                },
                header: {
                    slogan: 'REIMAGINE YOUR DATA',
                    glogin: 'התחבר עם גוגל'
                },
                sidebar: {
                    new: 'שיחה חדשה',
                    home: 'בית',
                    library: 'ספרייה'
                },
                history:{
                    title: 'היסטוריה'
                },
                sources: {
                    view: 'הצג',
                    download: 'הורד',
                    close: 'סגור'
                },
                avatar:{
                    title: 'יועץ'
                },
                conversation: {
                    expand: 'לחץ לפרטים',
                    answer: 'תשובה',
                    sources: 'מקורות',
                },
                login:{
                    label: 'התחבר'
                },
                stepper: {
                    next: 'הבא',
                    back: 'הקודם'
                },
                footer: {
                    blog: 'בלוג',
                    linkedin: 'לינקדאין',
                    phone: '050-4949469',
                    email: 'אימייל'
                },
                settings: {
                    title: 'הגדרות מערכת',
                    instagram: {
                        connect: 'התחבר לאינסטגרם',
                        reconnect: 'התחבר שוב לאינסטגרם',
                        expiration: 'מפתח אינסטגרם יפוג ב'
                    }
                }
            },
        }
    }});

export default i18n;
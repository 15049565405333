import { 
    Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AdvisorConversation from './AdvisorConversation';
import AdvisorHome from './AdvisorHome';
import Sidebar from './Sidebar';
import { styled } from '@mui/material/styles';
import AdvisorHeader from './AdvisorHeader';
import Settings from './Settings';

const MainContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, width }) => ({
      width:'100%',
      backgroundColor: theme.palette.background.default,
      direction: theme.direction,
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(theme.direction === 'ltr' && {
        marginLeft: `calc(${theme.spacing(9)} + 1px)`,
      }),
      ...(theme.direction === 'rtl' && {
        paddingRight: theme.spacing(14)
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        ...(theme.direction === 'ltr' && {
          marginLeft: `${width}px`
        }),
        ...(theme.direction === 'rtl' && {
          marginRight: `${width}px`,
          paddingRight: 0
        }),
      })
    }),
  );


export default function AdvisorView(){
    const sidebarOpen = useSelector((state) => state.sidebarOpen);
    const drawerWidth = useSelector((state) => state.drawerWidth);
    const inconversation = useSelector((state) => state.inconversation);
    const insettings = useSelector((state) => state.insettings);

    return (
        <>
            <AdvisorHeader />
            <Sidebar />
            <MainContainer open={sidebarOpen} width={drawerWidth}>
                {
                    insettings ? <Settings /> : 
                    inconversation ? <AdvisorConversation/> : <AdvisorHome />
                }
            </MainContainer>
        </>
    )
}
const SET_USER = 'SET_USER';
const LOAD_USER = 'LOAD_USER';
const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
const SET_LASTCONVERSATION = 'SET_LASTCONVERSATION';
const LOAD_LASTCONVERSATION = 'LOAD_LASTCONVERSATION'
const ADD_LASTCONVERSATION_ACTIVITY = 'ADD_LASTCONVERSATION_ACTIVITY';
const SET_LOCALE = 'SET_LOCALE';
const SET_THEME_MODE = 'SET_THEME_MODE';
const SET_THEME_DIRECTION = 'SET_THEME_DIRECTION';
const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
const SET_HISTORY_OPEN = 'SET_HISTORY_OPEN';
const SET_AVATAR_OPEN = 'SET_AVATAR_OPEN';
const SET_INCONVERSATION = 'SET_INCONVERSATION';
const SET_INSETTINGS = 'SET_INSETTINGS';
const SET_ADVISOR_LOADING = 'SET_ADVISOR_LOADING';

const SEND_ACTION_MESSAGE = 'SEND_ACTION_MESSAGE';
const SET_DISPLAYED_BOT_MESSAGE = 'SET_DISPLAYED_BOT_MESSAGE';
const SET_BOT_TYPING = 'SET_BOT_TYPING';
const SET_BOT_UPLOADING = 'SET_BOT_UPLOADING';


function loadUserFromLocalStorage(){
  const itemStr = localStorage.getItem('user');
  if (!itemStr) {
      return {
          type: LOAD_USER,
          payload: null,
      };
  }

  const item = JSON.parse(itemStr);
  const now = new Date().getTime();

  if (now > item.expiry) {
      localStorage.removeItem('user');
      return {
          type: LOAD_USER,
          payload: null,
      };
  }
  return {
      type: LOAD_USER,
      payload: item.value,
  };
};

function setUser(user) {
  const ttl = 3600 * 1000; // Time to live in milliseconds (e.g., 1 hour)
  const now = new Date().getTime();
  const item = {
      value: user,
      expiry: now + ttl,
  };
  localStorage.setItem('user', JSON.stringify(item));
  return {
    type: SET_USER,
    payload: { user }
  };
}


function loadLastConversationFromLocalStorage(){
  const itemStr = localStorage.getItem('lastConversation');
  if (!itemStr) {
      return {
          type: LOAD_LASTCONVERSATION,
          payload: {
            activities: []
          },
      };
  }

  const item = JSON.parse(itemStr);
  const now = new Date().getTime();

  if (now > item.expiry) {
      localStorage.removeItem('lastConversation');
      return {
          type: LOAD_LASTCONVERSATION,
          payload: {
            activities: []
          },
      };
  }
  return {
      type: LOAD_LASTCONVERSATION,
      payload: item.value,
  };
};

function setLastConversation(lastConversation) {
  const ttl = 3600 * 1000; // Time to live in milliseconds (e.g., 1 hour)
  const now = new Date().getTime();
  const item = {
      value: lastConversation,
      expiry: now + ttl,
  };
  localStorage.setItem('lastConversation', JSON.stringify(item));
  return {
    type: SET_LASTCONVERSATION,
    payload: { lastConversation }
  };
}

function addLastConversationActivity(activity){
  return {
    type: ADD_LASTCONVERSATION_ACTIVITY,
    payload: { activity }
  };
}

function setConversations(conversations) {
  return {
    type: SET_CONVERSATIONS,
    payload: { conversations }
  };
}

function setLocale(locale) {
  return {
    type: SET_LOCALE,
    payload: { locale }
  };
}

function setThemeMode(themeMode) {
  return {
    type: SET_THEME_MODE,
    payload: { themeMode }
  };
}

function setThemeDirection(themeDirection) {
  return {
    type: SET_THEME_DIRECTION,
    payload: { themeDirection }
  };
}

function setSidebarOpen(sidebarOpen) {
  return {
    type: SET_SIDEBAR_OPEN,
    payload: { sidebarOpen }
  };
}


function setHistoryOpen(historyOpen) {
  return {
    type: SET_HISTORY_OPEN,
    payload: { historyOpen }
  };
}

function setAvatarOpen(avatarOpen) {
  return {
    type: SET_AVATAR_OPEN,
    payload: { avatarOpen }
  };
}

function setDisplayedBotMessage(message) {
  return {
    type: SET_DISPLAYED_BOT_MESSAGE,
    payload: { message }
  };
}

function setBotTyping(typing) {
  return {
    type: SET_BOT_TYPING,
    payload: { typing }
  };
}

function setInconversation(inconversation) {
  return {
    type: SET_INCONVERSATION,
    payload: { inconversation }
  };
}

function setInsettings(insettings) {
  return {
    type: SET_INSETTINGS,
    payload: { insettings }
  };
}

function setAdvisorLoading(isLoading) {
  return {
    type: SET_ADVISOR_LOADING,
    payload: isLoading
  };
}

function setBotUploading(uploading) {
  return {
    type: SET_BOT_UPLOADING,
    payload: { uploading }
  };
}

function sendActionMessage(message) {
  return {
    type: SEND_ACTION_MESSAGE,
    payload: { message }
  };
}

export { 
  SET_USER, setUser, 
  LOAD_USER, loadUserFromLocalStorage,
  SET_CONVERSATIONS, setConversations,
  SET_LASTCONVERSATION, setLastConversation, 
  ADD_LASTCONVERSATION_ACTIVITY, addLastConversationActivity,
  LOAD_LASTCONVERSATION, loadLastConversationFromLocalStorage,
  SET_LOCALE, setLocale,
  SET_THEME_MODE, setThemeMode,
  SET_THEME_DIRECTION, setThemeDirection,
  SET_SIDEBAR_OPEN, setSidebarOpen,
  SET_HISTORY_OPEN, setHistoryOpen,
  SET_AVATAR_OPEN, setAvatarOpen,
  SET_INCONVERSATION, setInconversation,
  SET_INSETTINGS, setInsettings,
  SET_ADVISOR_LOADING, setAdvisorLoading,
  SET_DISPLAYED_BOT_MESSAGE, setDisplayedBotMessage,
  SET_BOT_TYPING, setBotTyping,
  SET_BOT_UPLOADING, setBotUploading,
  SEND_ACTION_MESSAGE, sendActionMessage
};
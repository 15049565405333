// File: AdvisorHome.jsx

import React, { useRef, useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  IconButton, 
  Button, 
  Typography,
  Grid, 
  InputAdornment 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { t } from 'i18next';
import { useTheme, styled } from '@mui/material/styles';
import { hooks } from 'botframework-webchat-component';
import { useSelector, useDispatch } from 'react-redux';
import { fetchQuestions } from './store/asyncActions';
import AttachFileButton from './AttachFileButton';

const { usePostActivity, useSendMessage } = hooks;

const HomeBox = styled('div', {})(({ theme, width, open }) => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  marginTop: theme.spacing(5), 
  minHeight: '100vh',
  marginRight: theme.spacing(10),
  ...(open && {
    marginRight: `${width}px`
  })
}));

export default function AdvisorHome() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const postActivity = usePostActivity();
  const sendMessage = useSendMessage();
  const [boxValue, setBoxValue] = useState('');
  const [attachedFiles, setAttachedFiles] = useState([]); // State to store attached files
  const promptRef = useRef(null);
  const questions = useSelector((state) => state.questions);
  const sidebarOpen = useSelector((state) => state.sidebarOpen);
  const drawerWidth = useSelector((state) => state.drawerWidth);

  useEffect(() => {
    dispatch(fetchQuestions(theme.lang));
  }, [dispatch, theme.lang]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleValueChange = (event) => {
    setBoxValue(event.target.value);
  };

  const handleSubmit = () => {
    const message = boxValue.trim();

    if (attachedFiles.length > 0) {
      // Create a delimited string of file names
      const fileNamesString = attachedFiles.map(file => file.contentUrl).join(',');

      // Create event activity with file names string as value
      const eventActivity = {
        type: 'event',
        name: 'setConversationAttachments', // Custom event name
        value: fileNamesString,   // Delimited string of file names
      };

      // Send event activity with file names using postActivity
      postActivity(eventActivity);

      // Clear attached files after sending the event
      setAttachedFiles([]);
    }

    if (message) {
      // Send message activity without attachments
      sendMessage(message);

      // Clear the input after sending the message
      setBoxValue('');
    }
  };

  const handleAskQuestion = (question) => {
    setBoxValue(question);
    promptRef.current.focus();
  };

  const handleAttach = (uploadedFilesInfo) => {
    // Update the state with the attached files' information
    setAttachedFiles(prevFiles => [...prevFiles, ...uploadedFilesInfo]);
  };

  return (
    <HomeBox open={sidebarOpen} width={drawerWidth}>
      <Box sx={{
        width: '75%',
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h1" sx={{ textTransform: 'capitalize' }} gutterBottom>
          {t('header.slogan')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'top', width: '100%', mb: 2 }}>
          <TextField
            fullWidth
            inputRef={promptRef}
            onKeyDown={handleKeyPress}
            autoFocus
            onChange={handleValueChange}
            value={boxValue}
            variant="outlined"
            placeholder={t('sendBox.placeholder')}
            multiline
            rows={4}
            InputProps={{
              style: { fontSize: '1.5rem' },
              startAdornment: (
                <InputAdornment position="start" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                  <AttachFileButton handleAttach={handleAttach} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ alignSelf: 'flex-end', pb: 3 }}>
                  <IconButton onClick={handleSubmit} className="smallIconButton">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {questions.map((question, index) => (
            <Grid key={index} item>
              <Button
                onClick={() => handleAskQuestion(question.value)}
                variant="outlined"
                startIcon={
                  <SearchIcon
                    sx={{
                      ...(theme.direction === 'ltr' ? { mr: 1 } : { ml: 1, mr: 0, pr: 0 }),
                    }}
                  />
                }
              >
                {question.value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </HomeBox>
  );
}
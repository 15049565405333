import { 
    Container,
    Box,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useSelector, useDispatch } from 'react-redux';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import logo from './assets/logo.png';
import { t } from 'i18next';
import { setUser } from './store/asyncActions';

export default function Login(){
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const error = useSelector((state) => state.error);

    const handleSuccess = async (tokenResponse) => {
        dispatch(setUser(tokenResponse));
    }

    const handleError = (error) => {
        console.log(error);
        // Handle login error, possibly dispatch an action to show error message
    }

    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleError
    })

    return (
        <Container maxWidth='md'>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                {loading ? 
                    <Box sx={{ display: 'flex' }}><CircularProgress /></Box> :
                    <Box sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <img src={logo} alt="App Logo" style={{ width: '150px', marginBottom: '20px' }} />
                        <Typography variant="h6" component="h1" gutterBottom>
                            {t('header.slogan')}
                        </Typography>
                        <Button 
                            variant='outlined'
                            onClick={login}
                            endIcon={<GoogleIcon/>}
                            sx={{
                                m:4
                            }}
                        >
                            {t('header.glogin')}
                        </Button>
                        {error && <Typography variant="body1" color="error" gutterBottom>{error.message}</Typography> }
                    </Box>
                }
            </Box>
        </Container>
    )
}

import { createAction } from '@reduxjs/toolkit'; // Optional, for creating actions

// Define action types
import { setUser as setLocalUser, setLastConversation, setConversations } from './actions';
const SET_USER_REQUEST = 'SET_USER_REQUEST';
const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
const SET_USER_FAILURE = 'SET_USER_FAILURE';
const SET_CONVERSATION_REQUEST = 'SET_CONVERSATION_REQUEST';
const SET_CONVERSATION_SUCCESS = 'SET_CONVERSATION_SUCCESS';
const SET_CONVERSATION_FAILURE = 'SET_CONVERSATION_FAILURE';
const FETCH_CONVERSATIONS_REQUEST = 'FETCH_CONVERSATIONS_REQUEST';
const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS';
const FETCH_CONVERSATIONS_FAILURE = 'FETCH_CONVERSATIONS_FAILURE';
const ADD_CONVERSATION_ACTIVITY_REQUEST = 'ADD_CONVERSATION_ACTIVITY_REQUEST';
const ADD_CONVERSATION_ACTIVITY_SUCCESS = 'ADD_CONVERSATION_ACTIVITY_SUCCESS';
const ADD_CONVERSATION_ACTIVITY_FAILURE = 'ADD_CONVERSATION_ACTIVITY_FAILURE';
const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST';
const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';
const POST_PRINT_CONVERSATION_REQUEST = 'POST_PRINT_CONVERSATION_REQUEST';
const POST_PRINT_CONVERSATION_SUCCESS = 'POST_PRINT_CONVERSATION_SUCCESS';
const POST_PRINT_CONVERSATION_FAILURE = 'POST_PRINT_CONVERSATION_FAILURE';
const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

// Define action creators
const setUserRequest = () => ({
    type: SET_USER_REQUEST,
});
  
const setUserSuccess = (updatedObject) => ({
    type: SET_USER_SUCCESS,
    payload: updatedObject,
});
  
const setUserFailure = (error) => ({
    type: SET_USER_FAILURE,
    payload: error,
});

const setConversationRequest = () => ({
    type: SET_CONVERSATION_REQUEST,
});
  
const setConversationSuccess = (updatedObject) => ({
    type: SET_CONVERSATION_SUCCESS,
    payload: updatedObject,
});
  
const setConversationFailure = (error) => ({
    type: SET_CONVERSATION_FAILURE,
    payload: error,
});

const fetchConversationsRequest = () => ({
    type: FETCH_CONVERSATIONS_REQUEST,
});
  
const fetchConversationsSuccess = (updatedObject) => ({
    type: FETCH_CONVERSATIONS_SUCCESS,
    payload: updatedObject,
});
  
const fetchConversationsFailure = (error) => ({
    type: FETCH_CONVERSATIONS_FAILURE,
    payload: error,
});

const addConversationActivityRequest = () => ({
    type: ADD_CONVERSATION_ACTIVITY_REQUEST,
});
  
const addConversationActivitySuccess = (updatedObject) => ({
    type: ADD_CONVERSATION_ACTIVITY_SUCCESS,
    payload: updatedObject,
});
  
const addConversationActivityFailure = (error) => ({
    type: ADD_CONVERSATION_ACTIVITY_FAILURE,
    payload: error,
});

// Async action creator
export const addConversationActivity = (activity) => {
  return async (dispatch) => {
    dispatch(addConversationActivityRequest());
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const res = await fetch(`${apiUrl}/updateactivity`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                activity: activity
            }),
        });
        if (!res.ok) throw new Error(`Failed to add conversation activities. Status: ${res.status}`);
        const data = await res.json();
        const conversation = data.conversation;
        dispatch(addConversationActivitySuccess(conversation));
        dispatch(setLastConversation(conversation));
    } catch (error) {
      dispatch(addConversationActivityFailure(error.toString()));
    }
  };
};

export const fetchConversations = (userId, limit=10) => {
    return async (dispatch) => {
        dispatch(fetchConversationsRequest());
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const res = await fetch(`${apiUrl}/conversations?userId=${userId}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) throw new Error(`Failed to get user conversations. Status: ${res.status}`);
            const data = await res.json();
            const conversations = data.conversations;
            dispatch(fetchConversationsSuccess(conversations));
        } catch (error) {
          dispatch(fetchConversationsFailure(error));
        }
      };
}

export const setUser = (credentials) => {
    return async (dispatch) => {
      dispatch(setUserRequest());
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        let response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${credentials.access_token}`
        }
        });
    
        if (!response.ok) {
        throw new Error('Failed to fetch user info: ' + response.statusText);
        }
    
        const userInfo = await response.json();
        
        response = await fetch(`${apiUrl}/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ info: userInfo }),
        });
        let data = await response.json();
        const user = data.user;
        if(user){
            dispatch(setUserSuccess(user));
            dispatch(setLocalUser(user));
        }else{
            dispatch(setUserFailure(data));
        }
      } catch (error) {
        dispatch(setUserFailure(error));
      }
    };
};

export const setConversation = (userId, conversation) => {
    return async (dispatch) => {
      dispatch(setConversationRequest());
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        
        const response = await fetch(`${apiUrl}/conversation`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
                userId: userId, 
                conversation: conversation 
            }),
        });
        let data = await response.json();
        dispatch(setConversationSuccess(data.conversation));
        //dispatch(setLastConversation(data.conversation));
        //dispatch(fetchConversations(userId));
      } catch (error) {
        dispatch(setConversationFailure(error));
      }
    };
};

export const fetchQuestions = (lang='he') => {
    return async (dispatch) => {
        dispatch(fetchQuestionsRequest());
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const res = await fetch(`${apiUrl}/questions?lang=${lang}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) throw new Error(`Failed to get questions. Status: ${res.status}`);
            const data = await res.json();
            const questions = data.questions;
            dispatch(fetchQuestionsSuccess(questions));
        } catch (error) {
          dispatch(fetchQuestionsFailure(error));
        }
      };
}

const fetchQuestionsRequest = () => ({
    type: FETCH_QUESTIONS_REQUEST,
});
  
const fetchQuestionsSuccess = (updatedObject) => ({
    type: FETCH_QUESTIONS_SUCCESS,
    payload: updatedObject,
});
  
const fetchQuestionsFailure = (error) => ({
    type: FETCH_QUESTIONS_FAILURE,
    payload: error,
});

export const postPrintConversation = (id, lang = 'he') => {
    return async (dispatch) => {
        dispatch(postPrintConversationRequest());
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const res = await fetch(`${apiUrl}/print_conversation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    lang
                })
            });
            if (!res.ok) throw new Error(`Failed to print conversation. Status: ${res.status}`);

            const blob = await res.blob();

            // Create a link element, set the download attribute, and trigger a click to start the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `conversation_${id}.pdf`;
            document.body.appendChild(link);
            link.click();

            // Cleanup: remove the link element after download
            document.body.removeChild(link);

            dispatch(postPrintConversationSuccess({ id, lang }));
        } catch (error) {
            dispatch(postPrintConversationFailure(error));
        }
    };
}

const postPrintConversationRequest = () => ({
    type: POST_PRINT_CONVERSATION_REQUEST,
});
  
const postPrintConversationSuccess = (updatedObject) => ({
    type: POST_PRINT_CONVERSATION_SUCCESS,
    payload: updatedObject,
});
  
const postPrintConversationFailure = (error) => ({
    type: POST_PRINT_CONVERSATION_FAILURE,
    payload: error,
});


export const fetchSettings = (userId) => {
    return async (dispatch) => {
        dispatch(fetchSettingsRequest());
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const res = await fetch(`${apiUrl}/settings?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) throw new Error(`Failed to get settings. Status: ${res.status}`);
            const data = await res.json();
            const settings = data.settings;
            console.log(data);
            dispatch(fetchSettingsSuccess(settings));
        } catch (error) {
          dispatch(fetchSettingsFailure(error));
        }
    };
}

const fetchSettingsRequest = () => ({
    type: FETCH_SETTINGS_REQUEST,
});
  
const fetchSettingsSuccess = (updatedObject) => ({
    type: FETCH_SETTINGS_SUCCESS,
    payload: updatedObject,
});
  
const fetchSettingsFailure = (error) => ({
    type: FETCH_SETTINGS_FAILURE,
    payload: error,
});

export {
    FETCH_CONVERSATIONS_REQUEST, fetchConversationsRequest,
    FETCH_CONVERSATIONS_SUCCESS, fetchConversationsSuccess,
    FETCH_CONVERSATIONS_FAILURE, fetchConversationsFailure,
    SET_USER_REQUEST, setUserRequest,
    SET_USER_SUCCESS, setUserSuccess,
    SET_USER_FAILURE, setUserFailure,
    SET_CONVERSATION_REQUEST, setConversationRequest,
    SET_CONVERSATION_SUCCESS, setConversationSuccess,
    SET_CONVERSATION_FAILURE, setConversationFailure,
    ADD_CONVERSATION_ACTIVITY_REQUEST, addConversationActivityRequest,
    ADD_CONVERSATION_ACTIVITY_SUCCESS, addConversationActivitySuccess,
    ADD_CONVERSATION_ACTIVITY_FAILURE, addConversationActivityFailure,
    FETCH_QUESTIONS_REQUEST, fetchQuestionsRequest,
    FETCH_QUESTIONS_SUCCESS, fetchQuestionsSuccess,
    FETCH_QUESTIONS_FAILURE, fetchQuestionsFailure,
    POST_PRINT_CONVERSATION_REQUEST, postPrintConversationRequest,
    POST_PRINT_CONVERSATION_SUCCESS, postPrintConversationSuccess,
    POST_PRINT_CONVERSATION_FAILURE, postPrintConversationFailure,
    FETCH_SETTINGS_REQUEST, fetchSettingsRequest,
    FETCH_SETTINGS_SUCCESS, fetchSettingsSuccess, 
    FETCH_SETTINGS_FAILURE, fetchSettingsFailure
}
